@import "~@/styles/variables";























































.pwa-install-graphic {
  margin: -1rem -1rem 0;
  user-select: none;
  pointer-events: none;

  > img {
    display: inline-block;
    width: 50%;
  }
}
.message {
  font-size: $f-size-md;
  font-weight: $f-weight-book;
  margin-bottom: 3rem;
}
.modal-footer {
  margin: 1rem -1rem -1rem;
  background: $pp-cream;
  font-size: $f-size-md;
  font-weight: $f-weight-regular;
  justify-content: center;

  .install-icon {
    width: 28px;
    height: auto;
    vertical-align: top;
    color: $pp-primary-blue;
  }
}
